import React from "react";
import { useNavigate } from "react-router-dom";
import "./Style.css";
function LoginSingup() {
  const navigate = useNavigate();
  return (
    <div className="loginContainer">
      <div className="loginheader">
        <button onClick={() => navigate("/")}> back </button>
      </div>
      <h2>Login/Signup</h2>

      <div className="loginformcontainer">
        <form className="forminput">
          <div className="mb-3">
            <label for="exampleInput" className="form-label">
              Mobile Number
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInput"
              aria-describedby="emailHelp"
            />
            <div id="emailHelp" className="form-text">
              We'll never share your Mobile Number with anyone else.
            </div>
            <div className="otpcontainer"> 
            <button className="otp">Send OTP</button>
            </div>
           
          </div>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">
              OTP
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
            />
          </div>
         
          <button type="submit" className="btn Loginbtn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginSingup;
