import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../context/Context";
import "./Style.css";

function SecondNav({ displaylist, setDisplaylist }) {
  const { headers, setdata, heading, setheading } = useGlobalContext();
  const [apidata, setapidata] = useState([]);
  const [linktu, setlinktu] = useState(" ")

  const GetApiUrl =
    "https://api.sordiz.com/GetTakeAwayProducts?BusinessId=87D58FDD-8A4A-448A-8DFC-4B10289F4501";
  const getData = async (url) => {
    const response = await fetch(url);
    const Apidata = await response.json();
    setapidata(Apidata.takeAwayGroupProducts);
  };
  useEffect(() => {
    getData(GetApiUrl);
  }, []);
  const GRPLINK = (NAME) => {
    setheading(NAME);
    // if(NAME==="Appetisers"){
    //   setlinktu(Appetisers);
    // }
  };

  // console.log("test", heading);
  return (
    <>
      <div className="secondnavcontainer">
        <div className="secondnavinner">
          {/* {apidata.map((value) => {
            return (
              <a
              // href={heading}
                // onClick={() => {
                //   setheading(value.groupname);
                // }}
                onClick={() => GRPLINK(value.groupname)}
                className="secondnavbtn"
                href="#Noodle Dishes"
              >
                {value.groupname}
                </a>
            );
          })} */}
          <a href="#Appetisers">Appetisers</a>
          <a href="#Noodle Dishes"> Noodle Dishes</a>
          <a href="#Thai Salad">Thai Salad</a>
        </div>
      </div>
      <div style={{ background: "#e1dddd" }}>
        <h3>{heading}</h3>  
      </div>
    </>
  );
}

export default SecondNav;
