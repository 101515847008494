export const stripePaymentMethodHandler = async (data, cb) => {
  window.sessionStorage.getItem("takeid")
  const { amount, result  } = data;
  // console.log("amount",takeAwayOrderId);
  if (result.error) {
    // show error in payment form
    FailureFn()
    async function FailureFn()   {

      // console.log("into the function")

      const url = "https://api.sordiz.com/FailedOrderPayment";  
      
      let data2 = {
        orderGUID:window.sessionStorage.getItem("takeid"),
        amount:Number(data.amount),
        failureMessage:result.error.message,
        txCode : "string",
        clientSecret: "string"
      };
      await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data2),
        redirect: "follow",
      })
        .then((response) => response.json())
        .then(async (response) => {
          console.log(response);
          if (response.status === 1) {
            console.log("done",response);
          } else {
            
            console.log("error");
          }
        })
        .catch((err) => {
          
        });
     
    };
    cb(result);
  } else {
    const paymentResponse = await stripePayment({
    //  result: result,
      payment_method_id: result.paymentMethod.id,
      name: result.paymentMethod.billing_details.name,
      email: result.paymentMethod.billing_details.email,
      amount: amount,
    });
    console.log(paymentResponse);
    if(paymentResponse.failureMessage===" Payment Done"){
      ConfirmOrderPayment()
      console.log(paymentResponse.failureMessage)
      async function ConfirmOrderPayment()   {

        console.log("into the function")

        const url = "https://api.sordiz.com/ConfirmOrderPayment";  
        // const url="http://192.168.1.9/SordizApi/ConfirmOrderPayment";
        let data = {
          orderGUID:paymentResponse.orderGUID,
          clientSecret:paymentResponse.clientSecret,
          txCode:paymentResponse.txCode,
          paymentId : window.sessionStorage.getItem("payemtid"),
          paid:(window.sessionStorage.getItem("payemtid") ? true : false)
        };
        await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then(async (response) => {
            console.log(response);
            if (response.isSuccess === true) {
              console.log("chl gya bhai",response);
            } else {
              
              console.log("error");
            }
          })
          .catch((err) => {
            
          });
       
      };
      
    }
    else{
      console.log("notdone")
    }
    cb(paymentResponse);
  }
};

// place backend API call for payment
const stripePayment = async (data) => {
console.log(data);
window.sessionStorage.setItem("payemtid",data.payment_method_id)
console.log()
  let data1 = {
    orderGUID : window.sessionStorage.getItem("takeid"),
    amount : Number(data.amount),
    paymentType:true
  };
 
  const res = await fetch("https://api.sordiz.com/GetStripePaymentIntent", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data1),
  });
  return await res.json();
};

 