import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TakeAway from "./components/Takeaway";
import LoginSingup from "./components/LoginSingup";
import OrderHistory from "./components/leftoffcanvas/OrderHistory";
import Stripe from "./components/Stripe payment/Stripe";
import TermAndcondition from "./components/Termandcondition/TermAndcondition";
import FaqHome from "./components/Faq/FaqHome";
import PrivacyPolicy from "./components/Privacypolicypage/PrivacyPolicy";
import Disclamer from "./components/disclaimer/Disclamer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TakeAway />} />
          <Route path="/LoginSingup" element={<LoginSingup />} />
          <Route path="/OrderHistory" element={<OrderHistory />} />
          <Route path="/payment" element={<Stripe />} />
          <Route path="/term&condition" element={<TermAndcondition />} />
          <Route path="/Faq" element={<FaqHome />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Disclamer" element={<Disclamer/>} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
