import React, { useState } from "react";
import "./Style.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import UserPNG from "./UserPNG.png";
import { useGlobalContext } from "../context/Context";
import PositionedSnackbar from "./OtpToast";

function OffCanvasExample({ name, ...props }) {
  const [MobileNumber, setMobileNumber] = useState("");
  const [OtpEnter, setOtpEnter] = useState("");
  const [userName, setuserName] = useState("");
  const {
    CustomerId,
    setCustomerId,
    setlogin,
    setCardShow,
    cardshow,
    show,
    setShow,
    setuserlogined,
  } = useGlobalContext();

  const handleShow = () => setShow(true);
  const VerifyOtpApi = `https://api.sordiz.com/VerifyOtp?mobile=${MobileNumber}&otp=${OtpEnter}`;
  const GenrateOtpApiUrl = "https://api.sordiz.com/GenerateOtp";
  // console.log(props);
  function handleClose() {
    // console.log("cancel",props);
    // props.show={false}
    // props.show(false)
  }
  //  console.log("afterCancel",userName);
  async function OTPgENRATE(e) {
    e.preventDefault();
if(userName === ""){
  alert("Enter User Name")
}else if( MobileNumber === ""){
  alert("Enter Mobile Number")
}else{
    let data = {
      // customerId: 0,
      mobile: MobileNumber,
      username: userName,
    };
    await fetch(GenrateOtpApiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        if (response.message === "OTP generated successfully") {
          console.log(response.message);
          // window.open("/OrderHistory","_self")
        } else {
          console.log(response.message);
        }
      })
      .catch((err) => {});
    }
  }
  let islogin = JSON.parse(localStorage.getItem("islogin"));
  let iscardshow = JSON.parse(localStorage.getItem("cardshow"));
  const VerifyOtp = async (e) => {
    e.preventDefault();
    const response = await fetch(VerifyOtpApi);
    const data = await response.json();
    // console.log(data);

    if (data.message === "Login successful") {
      // ! localstorage data code
      localStorage.setItem("customerID", data.data.customerId);
      localStorage.setItem("customerName", data.data.username);
      localStorage.setItem("customerMobileNumber", data.data.mobile);
      let id = localStorage.getItem("customerID");
      let username = localStorage.getItem("customerName");
      // console.log("customerrrrrrrrrrrrrrrrrrrrrrrnameeeeeee", username);
      // console.log("customerrrrrrrrrrrrrrrrrrrrrrriddddddddddddd", id);
      // ! localstorage data code
      setuserlogined(true);
      // setlogin(false);
      // setCardShow(true);
      localStorage.setItem("islogin", false);
      localStorage.setItem("cardshow", true);
      islogin = JSON.parse(localStorage.getItem("islogin"));
      iscardshow = JSON.parse(localStorage.getItem("cardshow"));
    }
    else{
      alert("Invalid OTP")
    }
    setCustomerId(data.data.customerId);
  };

  function cancel(e) {
    e.preventDefault();
    setShow(false);
    localStorage.setItem("islogin", false);
    islogin = JSON.parse(localStorage.getItem("islogin"));
    // console.log(true);
    setlogin(false);
    // window.open("/", "_self");
  }
  return (
    <>
      {/* <button
        style={{ border: "none", borderRadius: "7px" }}
        onClick={handleShow}
        className="me-2"
      >
        <img style={{ width: "15px" }} src={UserPNG} alt="" />
      </button> */}
      <Offcanvas
        style={{ marginTop: "2.5rem", transition: "all 1s ease 0s" }}
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>Login / Signup</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="loginContainer">
            <div className="loginformcontainer">
              <form className="forminput">
                <div className="mb-3">
                  <label className="form-label">User Name </label>
                  <input
                    className="form-control"
                    aria-describedby="emailHelp"
                    style={{ marginBottom: "22px" }}
                    onChange={(e) => setuserName(e.target.value)}
                  />
                  <label className="form-label">Mobile Number</label>
                  <input
                    className="form-control"
                    aria-describedby="emailHelp"
                    maxlength="10"
                    onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />

                  <div id="emailHelp" className="form-text">
                    We'll never share your Mobile Number with anyone else.
                  </div>
                  <div className="otpcontainer" onClick={OTPgENRATE}>
                    {/* <button className="otp" onClick={OTPgENRATE}>
                      Send OTP
                    </button> */}
                    <PositionedSnackbar />
                  </div>
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">
                    OTP
                  </label>
                  <input
                    type="password"
                    maxlength="4"
                    onChange={(event) => setOtpEnter(event.target.value)}
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div className="btncontainerLogin">
                  <button
                    onClick={VerifyOtp}
                    type="submit"
                    className="btn Loginbtn"
                  >
                    Submit
                  </button>
                  <button className="btn Loginbtn" onClick={cancel}>
                    cancel
                  </button>
                </div>

                {/* <button
                  onClick={Cancelfn}
                  type="submit"
                  className="btn Loginbtn"
                >
                  Cancel
                </button> */}
              </form>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Example({ show, setShow }) {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample
          key={idx}
          placement={placement}
          name={placement}
          show={show}
          setShow={setShow}
        />
      ))}
    </>
  );
}

export default Example;
