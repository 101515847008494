import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Style.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import hamburgerMenu from "./hamburgerMenu.png";
import FeedBackimg from "./FeedBackimg.png";
import giftBoximg from "./giftBoximg.png";
import ORDERHISTORYpng from "./ORDERHISTORYpng.png";
import Homepng from "./Homepng.png";
import menuimg from "./menuImg.png";
import Trackorder from "./leftoffcanvas/TrackOrder";
import OrderHistory from "./leftoffcanvas/OrderHistory";
function OffCanvas() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  let id = localStorage.getItem("customerID");
function orderhistoryfnc(){
  if(id===null){
   
    alert("Login first")
  }
  else{
    navigate("/OrderHistory")
  }
  
}
  return (
    <>
      <img
        onClick={handleShow}
        style={{ width: "22px", cursor: "pointer" }}
        src={hamburgerMenu}
        alt="menu"
      />

      <Offcanvas
        style={{
          marginTop: "2.5rem",
          background: "white",
          transition: "all 1s ease 0s",
        }}
        show={show}
        onHide={handleClose}
      >
        {/* <Offcanvas.Header
          style={{ justifyContent: "flex-end" }}
          closeButton
        ></Offcanvas.Header> */}
        <Offcanvas.Body>
          <ul style={{ listStyleType: "none" }}>
            <Trackorder />
            <button
              style={{
                position: "absolute",
                top: "12px",
                right: "-1px",
                border: "1px solid",
                background: "#7e5151",
                borderTopLeftRadius: "9px",
                color: "white",
                borderBottomLeftRadius: "9px",
              }}
              onClick={handleClose}
            >
              Cancel
            </button>
            <li>
              <div
                className="liofsidebar"
                onClick={orderhistoryfnc}
              >
               
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "61px",
                    marginLeft: "-30px",
                  }}
                  src={ORDERHISTORYpng}
                  alt=""
                />
                <p> Order History </p>
              </div>
              <div className="liofsidebar" onClick={() => navigate("/")}>
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "61px",
                    marginLeft: "-30px",
                  }}
                  src={Homepng}
                  alt=""
                />
                <p> Home </p>
              </div>
              {/* <div className="liofsidebar" onClick={() => navigate("/")}>
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "61px",
                    marginLeft: "-30px",
                  }}  
                  src={Homepng}
                  alt=""
                />
                <p> Deals & offers </p>
              </div> */}
              <div className="liofsidebar" onClick={() => navigate("/")}>
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "61px",
                    marginLeft: "-30px",
                  }}
                  src={menuimg}
                  alt=""
                />
                <p > Menu </p>
              </div>

              {/* <div className="liofsidebar" onClick={() => navigate("/")}>
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "61px",
                    marginLeft: "-30px",
                  }}
                  src={giftBoximg}
                  alt=""
                />
                <p > Everyday Value Offer </p>
              </div> */}
              <div className="liofsidebar" onClick={() => navigate("/term&condition")}>
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "61px",
                    marginLeft: "-30px",
                  }}
                  src={giftBoximg}
                  alt=""
                />
                <p >
                  {" "}
                  Terms & Conditions{" "}
                </p>
              </div>
              {/* <div className="liofsidebar" onClick={() => navigate("/")}>
                <img
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "61px",
                    marginLeft: "-30px",
                  }}
                  src={FeedBackimg}
                  alt=""
                />
                <p > Feedback</p>
              </div> */}
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default OffCanvas;
