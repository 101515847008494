import React, { useContext, useEffect, useState } from "react";
const AppContext = React.createContext();
const GetApiUrl ="https://api.sordiz.com/GetTakeAwayProducts?BusinessId=87D58FDD-8A4A-448A-8DFC-4B10289F4501";
const businessUrl = "https://api.sordiz.com/GetBusiness?hostname=tcbwigan";

function AppProvider({ children }) {
  const [loading, setloading] = useState(false);
  const [ordertype, setordertype] = useState(false);
  const [data, setdata] = useState([]);
  const [headers, setheaders] = useState([]);
  const [cartdata, setcartdata] = useState([]);
  const [total, settotal] = useState("");
  const [login, setlogin] = useState(false);
  const [heading, setheading] = useState("Mains");
  const [CustomerId, setCustomerId] = useState();
  const [cardshow, setCardShow] = useState(false);
  const [show, setShow] = useState(false);
  const [businessdata, setBusinessData] = useState("");
  const [isdisplay, setIsDisplay] = useState(false);
  const [userlogined, setuserlogined] = useState(false);
  const [DeliveryAddress, setDeliveryAddress] = useState("");
  const [takeAwayOrderId, settakeAwayOrderId] = useState("");
  useEffect(() => {
    getData(GetApiUrl);
    GetBusiness(businessUrl);
  }, []);

  const getData = async (url) => {
    setloading(true);
    const response = await fetch(url);
    const Apidata = await response.json();
    setdata(Apidata.takeAwayProducts);
    //  console.log(Apidata,"Apidata");

    // setheaders(Apidata.TakeAwayGroups);
    setloading(false);
  };
 
  const GetBusiness = async (url) => {
    setloading(true);
    const response = await fetch(url);
    const Data = await response.json();
    setBusinessData(Data.data.businessId);
    setIsDisplay(true);
    if (id === null) {
      localStorage.setItem("islogin", false);
      localStorage.setItem("cardshow", false);
    } else {
      localStorage.setItem("islogin", false);
      localStorage.setItem("cardshow", true);
    }
    // setheaders(Apidata.TakeAwayGroups);
    setloading(false);
  };
  // let islogin = localStorage.getItem("islogin");
  // let iscardshow = localStorage.getItem("cardshow");
  // console.log("data",data);
  // console.log("testttttttttttttttttttt",cartdata);
  let id = localStorage.getItem("customerID");
  // console.log("customerrrrrrrrrrrrrrrrrrrrrrriddddddddddddd", id);
  let username = localStorage.getItem("customerName");
  // console.log("customerrrNAMEEEEEE", username);
  return (
    <AppContext.Provider
      value={{
        loading,
        setloading,
        data,
        setdata,
        cartdata,
        setcartdata,
        headers,
        settotal,
        total,
        setlogin,
        login,
        setheading,
        heading,
        setCustomerId,
        CustomerId,
        cardshow,
        setCardShow,
        show,
        setShow,
        isdisplay,
        businessdata,
        userlogined,
        setuserlogined,
        DeliveryAddress,
        setDeliveryAddress,
        takeAwayOrderId,
        settakeAwayOrderId,
        setordertype,
        ordertype
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useGlobalContext };
