import React from "react";
import Cartempty from "./Cartempty.jpg";
function CartEmpty() {
  return (
    <div className="cartEmptyContainer">
      <h3>Cart Is Empty</h3>
      <p>Please add some items from the menu.</p>
      <img style={{width:"16rem"}}src={Cartempty} alt="cart is empty " />
    </div>
  );
}

export default CartEmpty;
