import React, { useState } from "react";
import errorimg from "../context/errorimg.jpg";
import TakeawayNav from "./TakeawayNav";
import Card from "./Card";
import "./Style.css";
import LeftContainer from "./leftcontainerCmp/LeftContainer";
import SecondNav from "./SecondNav";
import { useGlobalContext } from "../context/Context";
import Footer from "./Footer/Footer";

export default function TakeAway() {
  const { data, cartdata, setcartdata, setdata, setlogin,isdisplay } = useGlobalContext();
  const [displaylist, setDisplaylist] = useState([]);
  const [currentprice, setCurrentPrice] = useState("")
  const add = (id) => {
    //!----------------------this is for API data-------------------------------
    setlogin(false);
    // localStorage.setItem("islogin", false);
    //   localStorage.setItem("cardshow", false);
    setdata((p) => {
      return p.map((item) => {
        if (item.productID === id) {
         
          return {
            ...item,

            productQty: item.productQty + 1,
            // price: item.price + item.price
          };
        } else {
          return item;
        }
      });
    });
    //!----------------------this is for API data-------------------------------
    //? ------------------this is for cartData -------------------
    let exist = false;

    let newdata = cartdata.map((item) => {
      if (item.productID === id) {
        exist = true;
        return {
          ...item,
          productQty: item.productQty + 1,
          // price: item.price + currentprice
        };
      } else {
        return item;
      }
    });
    if (!exist) {
      let newItem = [];
      data.forEach((item) => {
        if (item.productID === id) {
          newItem.push({
            ...item,
            // price:item.price + item.price,
            productQty: 1,
          });
        }
      });
      setcartdata((p) => {
        return [...p, ...newItem];
      });
      // console.log(newItem);
      return;
    }

    setcartdata(newdata);
    //? ------------------this is for cartData -------------------

    // console.log(newdata);
  };
  // console.log("testetststststst", data);
  const minus = (id) => {
    //!----------------------this is for API data-------------------------------
    setlogin(false);
    setdata((p) => {
      return p.map((item) => {
        if (item.productID === id && item.productQty >= 1) {
          
          setCurrentPrice(item.price)
          
          return {
            ...item,

            productQty:
              item.productQty === 0 ? item.productQty : item.productQty - 1,
          };
        } else {
          return item;
        }
      });
    });
    //!----------------------this is for API data-------------------------------
    //? ------------------this is for cartData -------------------
    let exist = false;

    let newdata = [];
    cartdata.forEach((item) => {
      if (item.productID === id) {
        exist = true;
        
        if (item.productQty >= 1) {
          // console.log("data",currentprice);
          newdata.push({
            ...item,

            productQty:
              item.productQty === 0 ? item.productQty : item.productQty - 1,
              // price: item.price === 0 ? item.price : item.price - currentprice
          });
        }
      } else {
        newdata.push(item);
      }
    });
    if (!exist) {
      // console.log(data, id);
      let newItem = [];
      data.forEach((item) => {
        if (item.productID === id) {
          newItem.push({
            ...item,
            // price: item.price === 0 ? item.price : item.price - currentprice,
            productQty: 0,
            // price: item.price - item.price
          });
        }
      });
      setcartdata((p) => {
        return [...p, ...newItem];
      });
      // console.log(newItem);
      return;
    }

    setcartdata(newdata);
    //? ------------------this is for cartData -------------------

    // console.log(newdata);
  };

  return (
    <>
    {/* {isdisplay ?  */}
       <>
       <TakeawayNav />
       <div style={{ marginTop: "2.6rem" }} className="headeroffood">
         {/* <SecondNav displaylist={displaylist} setDisplaylist={setDisplaylist} /> */}
       </div>
       <div className="fullcontainer">
         <div className="takeAwaycontainer">
           <Card
             add={add}
             minus={minus}
             displaylist={displaylist}
             setDisplaylist={setDisplaylist}
           />
         </div>
         <LeftContainer add={add} minus={minus} />
       </div>
       <Footer />
     </>
     {/* : <>
         <div className="error-img" >
          <img style={{width:"60rem"}} src={errorimg}></img>
        </div>
     </>
  } */}
   
    </>
  );
}
