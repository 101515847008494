import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import chili from "../chili.png";

function MyVerticallyCenteredModal(props) {
  return (
    <>
      {/* { console.log(props)} */}
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header  style={{background:"rgb(118 83 83)",color:"white"}}closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h3 style={{ fontWeight: 700 }}>
                {props.data.description}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  style={{background:"rgb(118 83 83)",color:"white"}}>
            <p style={{ color: "white" }}>
              {" "}
              {props.data.menuDescripton ? (
                props.data.menuDescripton
              ) : (
                <span>Description not available</span>
              )}{" "}
            </p>
            <p style={{ marginTop: "2px" }}>
              <span style={{ fontWeight: 600 }}>Allergens</span>:{" "}
              {props.data.vAllergens ? (
                props.data.vAllergens
              ) : (
                <span>not available</span>
              )}
            </p>
            <div style={{ display: "flex" }} className="spiceCont">
              <span>
                {" "}
                <b>Spicy </b> :
              </span>{" "}
              {props.data.bSpicy <= 0 ? (
                <div>No spice</div>
              ) : (
                <div>
                  {[...Array(props.data.bSpicy)].map((e) => (
                    <img
                      style={{ width: "15px", height: "15px" }}
                      src={chili}
                      alt=""
                    />
                  ))}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer style={{background:"rgb(118 83 83)",color:"white"}}>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer >
        </Modal>
      </>
    </>
  );
}

function Modals({ data, modalShow, setModalShow }) {
  console.log("sahil", data);
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </>
  );
}

export default Modals;
