import React from "react";
import { useNavigate } from "react-router-dom";
import "../Footer/Footer.css";
function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <div className="FooterContainer">
        <div className="innerFooterContainer">
          <div className="companyName">
            <p>SORDIZ</p>
          </div>
          <div className="routinPagelink">
            <ul>
              <li onClick={() => navigate("/Disclamer")}  >Disclaimer </li>
              </ul>
              <ul> 
              <li onClick={() => navigate("/privacy-policy")} >Privacy Policy</li>
              </ul>
              <ul> 
              <li onClick={() => navigate("/Faq")}>Faq</li>
              </ul>
              <ul> 
              <li onClick={() => navigate("/term&condition")}>Terms & condition</li>
              </ul>
              {/* <ul>  
                
              <li>Feedback</li>
            </ul> */}
          </div>
        </div>
        <div className="allRightReserved">
            <p>All Rights Reserved Copyright Belinnov Solutions Pvt. Ltd.</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
