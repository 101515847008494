import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Style.css";
import BasicRating from "./StarRating";
function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [feedback, setFeedBack] = useState("")
  const [starval, setStarVal] = useState("")
  const [loader, setLoader] = useState(false);
  const [popshow, setPopshow] = useState(false);
  const [catcherror, setCatchError] = useState(false);
  const [error, setError] = useState("");
  const [color, setColor] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log("feed",props.value.value);
  // console.log("val",starval);
  let cusid = localStorage.getItem("customerID");
let typeoforder = props.value.value.delivery ? "delivery" : "PickUp"

  async function feedbacksubmit() {
   if(starval === null){
    alert("Rate us by clicking on stars")
   }
     else if(feedback === ""){
      alert("Feedback box must not be empty")
     }else{

      setLoader(true);
      const url = "https://api.sordiz.com/SubmitFeedback";
      const formData = new FormData();
      formData.append("CustomerId", Number(cusid));
      formData.append("OrderNo", props.value.value.orderNumber);
      formData.append("OrderType", typeoforder);
      formData.append("OverAllRating",Number (starval))
      formData.append("Feedback",feedback)

      await fetch(url, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: formData,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then(async (response) => {
          console.log(response);
          if (response.sucess === true) {
            setLoader(false);
            setPopshow(true);
            setColor("#8e8e7c");
            setError(response.message);
            // window.open("/productgroupAdd", "_self");
            console.log(response.message);
          } else {
            setLoader(false);
            setPopshow(true);
            setColor("#C70100");
            setError(response.message);
            console.log(response.message);
          }
        })
        .catch((err) => {
          setCatchError(true);
          setColor("#C70100");
        });
      }
  }

  return (
    <>
      <button className="btnfeedback" onClick={handleShow}>
        Feedback
      </button>
      <Offcanvas
        style={{ marginTop: "2.5rem", transition: "all 1s ease 0s" }}
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Feedback</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        {loader ? (
        <div className="loader-wrapper" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
       {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className="cardbox" style={{ backgroundColor: color }}>
                <div className="erroriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <p className="text-center text-white">{error}</p>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn"
                    onClick={() => {
                      setPopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="containerFeedback">
            <div className="feedbackheader">
              <p>Order No. {props.value.value.orderNumber}</p>
              <p>Price £{props.value.value.orderTotal}</p>
              <p>
                Order placed on {props.value.value.dateCreated.slice(0, 10)}
              </p>
            </div>
            <div className="writeFeedbackcontainer">
              <p className="tellushead">Tell us your feedback</p>
              <BasicRating setStarVal={setStarVal} />
              <textarea rows="6" cols="42"  onChange={(e) => setFeedBack(e.target.value)} value={feedback}></textarea>
            </div>
            <div className="submitfeedback">
              <button className="submitfeedbackBtn" onClick={feedbacksubmit}>Submit</button>
            </div>
            <div className="footerfeedback">
              <p>Your feedback is valuable for us </p>
            </div>
          </div>
          {popshow ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv">
                  <div className="cardbox" style={{ backgroundColor: color }}>
                    {color === "#C70100" ? (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-badge-chech"></i>
                        </div>
                      </>
                    )}

                    <br />
                    <p className="text-center text-white">{error}</p>
                    <br />
                    <div className="mainerrorclosebtn">
                      {color === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn"
                            id="close-pop"
                            onClick={() => {
                              setPopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </>
                      ) : (
                        <div
                          className="errorclosebtn"
                          id="close-pop"
                          onClick={() => {
                            setPopshow(false);
                          }}
                        >
                          <a href="/OrderHistory" style={{color:"white",textDecoration:"none"}}><p>OK</p></a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Feedback(value) {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample
          key={idx}
          placement={placement}
          name={placement}
          value={value}
        />
      ))}
    </>
  );
}

export default Feedback;
