import React, { useState } from "react";

import Offcanvas from "react-bootstrap/Offcanvas";
import "../Style.css";
import Locationpng from "../Locationpng.png";
function Trackorder() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <li>
        <div className="liofsidebar">
          <img
            style={{
              width: "19px",
              height: "27px",
              marginRight: "61px",
              marginLeft: "-30px",
            }}
            src={Locationpng}
            alt=""
          />
          <p onClick={handleShow}> Track Your order </p>
        </div>
      </li>

      <Offcanvas
        style={{ marginTop: "2.5rem", transition: "all 1s ease 0s" }}
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>TRACK ORDER</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="trackcontainer">
            <div className="trackinner">
              <div className="inputcontainerTrack">
                <p className="head1track">MOBILE NUMBER</p>
                <input
                  style={{
                    height: "1.7rem",
                    border: "2px solid grey",
                    fontSize: "0.8rem",
                    color: "#362929",
                  }}
                  type="text"
                  placeholder="+91"
                  name=""
                  id=""
                />
                <p className="head2track">ORDER ID</p>
                <input
                  style={{
                    height: "1.7rem",
                    border: "2px solid grey",
                    fontSize: "0.8rem",
                    color: "#362929",
                  }}
                  type="text"
                  name=""
                  id=""
                />
                <button className="trackbtn">SUBMIT</button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Trackorder;
