import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import "./Style.css";
export default function PositionedSnackbar() {
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const buttons = (
    <React.Fragment>
      <Button
        //   variant="outlined"
        onClick={handleClick({
          vertical: "top",
          horizontal: "right",
        })}
      style={{    color: "white",
        border: "none",
        fontWeight: 500,
        borderRadius: "9px",
        background: "#a15050"}}
      >
        Send OTP
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      {buttons}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        color="success"
        onClose={handleClose}
        message="OTP Genrated succesfully  &#128512;" 
        style={{ backgroundColor: "green !important" }}
        key={vertical + horizontal}
      />
    </div>
  );
}
