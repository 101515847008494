import React from "react";
import { useState } from "react";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import { useGlobalContext } from "../../context/Context";
import "../Style.css";
import TakeawayNav from "../TakeawayNav";
import NoOrder from "./NoOrder";
import OrderDetails from "./OrderdetailCanvas";
import Feedback from "./Feedback";
import Table from "react-bootstrap/Table";
function OrderHistory() {
  const { CustomerId, userlogined } = useGlobalContext();
  let id = localStorage.getItem("customerID");
  // console.log("customerrrrrrrrrrrrrrrrrrrrrrriddddddddddddd", id);
  const getOrderHistroryUrl = `https://api.sordiz.com/GetMyOrders?custId=${id}`;
  const [orderHistorycard, setorderHistorycard] = useState([]);
  const [show, setshow] = useState(false);
  useEffect(() => {
    const OrderHistory = async () => {
      // if(userlogined===false){
      //   alert("login first")
      // }




      const response = await fetch(getOrderHistroryUrl);
      const data = await response.json();
      // console.log("sauil", data.data);
      setorderHistorycard(data.data);
      // console.log(data.data.length);
      if (data.data.length === 0) {
        setshow(true);
      } else setshow(false);
    
    };
    OrderHistory();
  }, []);

  let arr = [];
  // console.log("ssssssssss", orderHistorycard);
  orderHistorycard.map((item) => {
    arr.push(item.orderedItems.length);
  });
  // console.log("testarr", arr);

  return (
    <>
      <div className="OrderHistoryContainer">
        <TakeawayNav />
        {/* <NoOrder/> */}
        <div className="orderhistorycardcontainer">
          <h2 className="headingHistory"> ALL ORDERS</h2>
          {show ? (
            <>
              <NoOrder />{" "}
            </>
          ) : (
            <>
              <div className="test">
                {orderHistorycard.map((value) => {
                  return (
                    <div className="historycard">
                      <div className="historyheader">
                        <p
                          style={{
                            padding: "0.8px",
                            fontSize: "11px",
                            fontWeight: 900,
                          }}
                        >
                          ORDER NO :{value.orderNumber}{" "}
                        </p>
                        <p
                          style={{
                            fontWeight: 900,
                            color: "green",
                            paddingLeft: "6px",
                          }}
                        >
                          Amount  £{value.orderTotal}
                        </p>
                      </div>
                      <div className="dateorderhistorycont">
                        <p> Date:{value.dateCreated.slice(0, 10)} </p>
                      </div>
                      <div className="itemshistory">
                        <Table striped bordered hover style={{overflowY:"scroll"}}>
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Qty</th>
                            </tr>
                          </thead>
                          {value.orderedItems.map((data) => {
                            return (
                              <tr>
                                <td> {data.description} </td>
                                <td>{data.qty}</td>
                              </tr>
                            );
                          })}
                        </Table>
                      </div>
                      <div className="historybtn">
                        <OrderDetails value={value} />
                        <Feedback value={value} />
                      </div>
                    </div>
                  );
                })}
              </div>{" "}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrderHistory;
