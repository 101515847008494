import React from 'react'
import "../Style.css"
import Cart from './Cart'

function LeftContainer({add,minus}) {
  
  return (<> 
  <div className="leftcontainer">
 <Cart add={add} minus={minus}/>
  </div>
   
    </>
  )
}

export default LeftContainer