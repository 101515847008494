import React, { useState } from "react";

import Offcanvas from "react-bootstrap/Offcanvas";
import { useGlobalContext } from "../../context/Context";
import Tickimg from "./Tickimg.png";
import "../Style.css";
import CartEmpty from "../leftcontainerCmp/CartEmpty";
import { useEffect } from "react";

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const [TimeSlotShow, setTimeSlotShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { cartdata } = useGlobalContext();
  const [AvailableSlot, setAvailableSlot] = useState([]);
  const [time, settime] = useState("12:45");
  const [Query, setQuery] = useState("");
  const [displayMessage, setDisplayMessage] = useState("");
  
  let subtotal = 0;
  cartdata.forEach((item) => {
    subtotal += Number(item.price * item.productQty);
    // console.log(subtotal);
  });
  const url =
    "http://tcbapi.thechinesebuffet.com/GetTakeAwaySlots?resId=1&type=Collection";
  const timeSlotfetch = async () => {
    setTimeSlotShow(true);
    const response = await fetch(url);
    const data = await response.json();
    // console.log("gdgdgdg", data);
    setAvailableSlot(data.ReservationSlots);
  };
  const arr = ["12:45", "13:45", "14:40", "15:45", "19:45", "20:15"];
  const timeclick = (e) => {
    setTimeSlotShow(false);
    // console.log(e);
    settime(e);
  };
  useEffect(() => {
    const timeOutId = setTimeout(() => setDisplayMessage(Query), 1000);
    return () => clearTimeout(timeOutId);
  }, [Query]);
  // console.log(displayMessage);
  const CHECKPROMO=()=>{
    // .find use hoga 
    // console.log("db gya ");
  }
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          onClick={handleShow}
          readOnly
        />
        <label className="form-check-label" htmlFor="flexRadioDefault2">
          Pick up
        </label>
      </div>

      <Offcanvas
        style={{ marginTop: "2.5rem", transition: "all 1s ease 0s" }}
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>PICK UP </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {cartdata && cartdata.length > 0 ? (
            <>
              {!TimeSlotShow ? (
                <>
                  <div className="pickupCont">
                    <div className="collection">
                      <img
                        style={{ width: "22px", height: "22px" }}
                        src={Tickimg}
                        alt=""
                      />
                      <div className="para">
                        {/* <p className="collectiontitle">
                          Collection from Bolton
                        </p> */}
                        <div className="TimeContainer">
                          <p className="TimeSlot">
                            Your estimated collection time is <b>{time}</b>
                          </p>
                          <button
                            className="TimeSlotChangeBtn"
                            onClick={timeSlotfetch}
                          >
                            Change
                          </button>
                        </div>

                        <p className="collectionpara2">
                          Please try collecting on time to avoid your food
                          getting cold{" "}
                        </p>
                      </div>
                    </div>
                    {cartdata.map((value) => {
                      return (
                        <div className="pickupcard">
                          <div className="pickupcardinner">
                            <p className="DESCRIPTIONPICKUP">
                              {value.description}
                            </p>
                            <p>QTY :{value.productQty}</p>
                            <p style={{ color: "green" }}>
                              £
                              {Math.round(
                                (value.price *
                                  value.productQty +
                                  Number.EPSILON) *
                                  100
                              ) / 100}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    <div className="promocode">
                      <p className="applypara">APPLY PROMOCODE </p>
                      <div className="promosearchAndBtn">
                        <input
                          className="inputpromocode"
                          type="text"
                          placeholder=""  
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                        />
                        <button className="promocheckbtn" onClick={CHECKPROMO}>Check</button>
                      </div>
                    </div>
                    <div className="subtotal">
                      <p>Subtotal</p>
                      <p>£{subtotal}</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="timeslotcont">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 style={{ color: "#5e5555" }}> Schedule your order</h5>
                      <button
                        className="backbtnTime"
                        onClick={() => setTimeSlotShow(false)}
                      >
                        back
                      </button>
                    </div>
                    <p style={{ marginTop: "1rem", fontWeight: 700 }}>
                      {" "}
                      Select a time to schedule your order{" "}
                    </p>
                    <div className="selectTimecont">
                      {arr.map((value) => {
                        return (
                          <button
                            onClick={() => timeclick(value)}
                            className="btntime"
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  {/* <p onClick={() => setTimeSlotShow(false)}>jhffg</p> */}
                </>
              )}
            </>
          ) : (
            <CartEmpty />
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Pickupoffcanvas({ add, minus }) {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default Pickupoffcanvas;
