import React, { useState } from "react";
import "./Style.css";
import { Navbar } from "react-bootstrap";
import OffCanvas from "./OffCanvas";
import Locationpng from "./Locationpng.png";
import Example from "./LoginOffcanvas";
import Deliveryoffcanvas from "./Radio offcanvas/DeliveryoffCanvas";
import Pickupoffcanvas from "./Radio offcanvas/PickupOffcanvas";
import { useGlobalContext } from "../context/Context";
import { useNavigate } from "react-router-dom";

function TakeawayNav() {
  const [longitude, setlongitude] = useState(``);
  const [latitude, setlatitude] = useState(``);
  const [location, setlocation] = useState("unkwown location");
  const API_key = "3552e404b1acfbe92810da8a838d3386";
  const API_Endpoints = "https://api.openweathermap.org/data/2.5/weather?";
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("customerID");
    localStorage.removeItem("customerName");
    localStorage.removeItem("DeliveryAddress");
    
    window.location.reload();
  };
  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setlatitude(position.coords.latitude);
      setlongitude(position.coords.longitude);
    });
    let finalendpoint = `${API_Endpoints}&lat=${latitude}&lon=${longitude}&appid=${API_key}`;

    const getdata = async () => {
      const response = await fetch(finalendpoint);
      const data = await response.json();

      setlocation(data.name);
    };
    getdata();
  });
  let username = localStorage.getItem("customerName");
  const { setShow, setlogin, show,setordertype,ordertype } = useGlobalContext();
  let islogin = JSON.parse(localStorage.getItem("islogin"));
  const LOGINFNC = () => {
    // console.log("pressed");
    localStorage.setItem("islogin", true);
    islogin = JSON.parse(localStorage.getItem("islogin"));
    setShow(true);
    // setlogin(false)
  };
  let DeliveryAddresslocal = localStorage.getItem("DeliveryAddress");  
  const ordertypefn=()=>{
    window.sessionStorage.setItem("pickup",true)
    window.sessionStorage.setItem("delievry",false)
  }
  const ordertypefn2=()=>{
    window.sessionStorage.setItem("delievry",true)
    window.sessionStorage.setItem("pickup",false)
  }
  return (
    <>
      <div className="navcont">
        <div className="inner">
          <div className="logobrand">
            <div className="hamburgermenu">
              <OffCanvas />
              <Navbar.Brand
                style={{ color: "white", marginLeft: "16px" , cursor:"pointer"}}
                onClick={() => navigate("/")}
              >
                SORDIZ
              </Navbar.Brand>
            </div>
            <div className="leftsidenav">
              <div className="radioBox" onClick={ordertypefn2}>
                {/*//!  delivery radio button imported !//*/}
                <Deliveryoffcanvas />
                {/*//!  delivery radio button imported !//*/}

                {/* ------------------------------------------------------------------------------------ */}
              
              </div>
              <div className="radioBox" onClick={ordertypefn}>
                  {/*//?  Pickup radio button imported ?//*/}
                  <Pickupoffcanvas />
                {/*//?  Pickup radio button imported ?//*/}
                </div>
              <img
                style={{
                  width: "16px",
                  height: "20px",
                  filter: "grayscale(1) invert(1)",
                }}
                src={Locationpng}
                alt=""
              />
              <input
                className="input"
                type="text"
                id="country"
                name="country"
                value={DeliveryAddresslocal?DeliveryAddresslocal:"select delivery location"}
                readOnly
              />
              <Example />
              {username ? (
                <>
                  {" "}
                  <p>Logged in: {username.toUpperCase()}</p>
                  <button onClick={logout} className="logoutBtn">
                    logout
                  </button>{" "}
                </>
              ) : (
                <>
                  {" "}
                  {islogin ? (
                    <>
                      <Example show={show} setShow={setShow} />
                    </>
                  ) : (
                    <>
                      <p className="userLoginbtn" onClick={LOGINFNC}>
                        Login{" "}
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TakeawayNav;
