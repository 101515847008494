import React from "react";
import Footer from "../Footer/Footer";
import TakeawayNav from "../TakeawayNav";
import "./termsCondition.css";
function TermAndcondition() {
  return (
    <>
      <div className="termsAndconditionWholeContainer">
        <TakeawayNav />
        <div className="contentTerms">
          <h2>Terms And Conditions</h2>
          <div className="content">
            <h3>Terms of use for Website</h3>
            <p>
              Our website, www.takeaway.sordiz.com are made available free of
              charge. We do not guarantee that the website and app, or any
              content on them, will always be available or be uninterrupted. We
              may suspend, withdraw, discontinue or change all or any part of
              the website or app without notice. We will not be liable to you if
              for any reason the website or app are unavailable at any time or
              for any period.
            </p>
            <p>
              You are responsible for making all arrangements necessary for you
              to have access to the website and app. You are also responsible
              for ensuring that all persons who access the websites and app
              through your internet connection are aware of these Terms and that
              they comply with them.
            </p>
            <p>
              The website and app and the content on them are provided for
              general information purposes only. They are not intended to amount
              to advice (medical or otherwise) on which you should rely.
            </p>
            <h3>Your account and password</h3>
            <p>
              You will need to register an account with us in order to access
              certain services available on the website and app. If you register
              an account, you will be asked to provide certain information (such
              as your email address) and to create a password, as part of our
              security procedures.
            </p>
            <p>
              We use Single Sign On (‘SSO’) for account management. This means
              that Registered Users can access all of our online services with
              one set of log in details.
            </p>
            <h3>General Acceptance Terms of Use</h3>
            <p>You agree not to:</p>
            <p>
              – use the website or app in any way that breaches these terms or
              any applicable local, national or international law or regulation;
            </p>
            <p>
              – copy, or otherwise reproduce or re-sell any part of the website
              or app unless expressly permitted to do so in by us; or
            </p>
            <p>
              – do any act or thing that might damage, disrupt or otherwise
              interfere with the operation of the website or app or any
              equipment, network or software used in operating the website or
              app
            </p>
            <h3>Viruses</h3>
            <p>
              We do not guarantee that the website or app will be secure or free
              from bugs or viruses. You are responsible for configuring your
              information technology, computer programmes and platform in order
              to access the website or app and we recommend that you use your
              own virus protection software.
            </p>
            <p>
              You must not misuse the website or app by knowingly introducing
              viruses, trojans, worms, logic bombs or other material which is
              malicious or technologically harmful. You must not attempt to gain
              unauthorised access to the website or app, the server on the
              website or app are stored, or any server, computer or database
              connected to the website or app. You must not attack the website
              or app via a denial-of-service attack or a distributed denial-of
              service attack. By breaching this provision, you would commit a
              criminal offence under the Computer Misuse Act 1990. We will
              report any such breach to the relevant law enforcement
              authorities, and we will co-operate with those authorities by
              disclosing your identity to them. In the event of such a breach,
              your right to use the website or app will cease immediately.
            </p>
            <h3>Our liability</h3>
            <p>
              We are not responsible for any loss or damage that is not
              foreseeable. Loss or damage is foreseeable if it was an obvious
              consequence of our breach or if it was contemplated by you and us
              at the time that you accessed the website or app.
            </p>
            <p>
              We only supply the website or app for domestic and private use.
              You agree not to use the website or app, or any content on the
              website or app, for any commercial or business purposes and we
              have no liability to you for any loss of profit, loss of business,
              business interruption, or loss of business opportunity.
            </p>
            <p>
              Nothing in these terms affects your statutory rights. Advice about
              your statutory rights is available from your local Citizens’
              Advice Bureau or Trading Standards Office.
            </p>
            <p>
              We assume no responsibility for the content of websites linked to
              the website or app or included in these terms (including links to
              any commercial sponsors or partners). Such links should not be
              interpreted as endorsement by us of those linked websites. We will
              not be liable for any loss or damage that may arise from your use
              of them.
            </p>
            <h3>Changes to these Terms</h3>
            <p>
              We may make changes to these terms from time to time (if, for
              example, there is a change in the law that means we need to change
              these Terms). Please check these Terms regularly to ensure that
              you understand the Terms that apply at the time that you access
              and use the website or app.
            </p>
            <h3>Privacy Policy</h3>
            <p>
              When you visit our website, sordiz takeaway download and install
              our mobile app, registered for our SMS promotions or for use one
              of our other services we will require you to provide us with your
              Personal Information (as defined below). We want to make sure you
              feel comfortable about what happens when you browse our website or
              give us any of your information.
            </p>
            <p>
              We will always use our best endeavours to make sure your Personal
              Information is safe and secure. This Privacy Policy explains how
              we collect, record and use the information you give us. It also
              explains the choices you can make about the way your information
              is collected and used. If you provide us with Personal
              Information, it means you’re giving us your permission to collect,
              use and transfer it in the ways set out in the Privacy Policy.
            </p>
            <h3>Do you share my Personal Information with anyone else?</h3>
            <p>
              We only share information with companies that we use to help us
              better understand and serve you. We never sell or give your
              Personal Information away to anybody.
            </p>
            <p>
              Where we share your Personal Information we ensure that they have
              agreed to be bound by this Privacy Policy and will only use it for
              the purposes outlined in this Privacy Policy.
            </p>
            <p>
              We may disclose Personal Information about you to other third
              parties to comply with applicable laws and regulations. We also
              have the right to disclose such Personal Information to third
              parties where there’s been a complaint concerning your use of our
              Service or where we believe that you haven’t complied with our
              Privacy Policy.
            </p>
            <p>
              When you place an order with us for delivery, we will only share
              the personal information required by our partners to fulfil your
              order. Likewise, if you use reserve a table at one of our
              restaurants or process an order at your table our carefully
              selected partners will only collect and process the personal
              information needed to deliver that service. We engage third party
              payment processors to process the payment of your online order.
              These third party processors may store your payment card details,
              if you want them to, to speed up your transaction time. If you do
              not want to take advantage of this, please do not select this
              function.
            </p>
            <p>
              We may also share Personal Information with third parties for
              marketing, advertising and strategic development purposes to help
              us understand customer trends and patterns and to make sure that
              we are talking to you about the most relevant things. We have
              Agreements in place that define what third parties can and cannot
              do with your Personal Information. We also conduct periodic
              reviews of third parties to ensure compliance. However we will
              only send marketing to you where you have previously consented to
              receiving marketing materials from us. We may also share such
              details with auditors or legal professionals to obtain
              professional advice. If we do this, your statutory data protection
              rights will be preserved. You should be aware that if we’re
              requested by the police, a regulatory or government authority in
              the investigation of suspected illegal activities to provide your
              Personal Information, then we are entitled do so.
            </p>
            <p>
              We are using customer insight tools to improve our websites and
              app. These services may record the screens you visit on our
              website and some high-level device information. They do not
              collect personally identifiable information and do not track your
              browsing habits across apps or websites.
            </p>
            <h3>What security measures are in place to protect my “Personal Information”?</h3>
            <p>The transmission of information via the internet is not completely secure. Although we take steps to protect your information, we can’t guarantee the security of your data transmitted to the Service; any transmission is at your own risk. We have implemented reasonable technical and organisational measures designed to secure your personal information from accidental loss and from unauthorised access, use, alteration or disclosure. We’ll continue to maintain and improve these security measures in line with legal and technological developments.</p>
            <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
            <h3>The use of our service by under 16s</h3>
            <p>Our Services are not intended for children and are therefore only to be used by anyone aged 16 or over and those under the age of 16 should have their parents or guardians’ permission. We encourage the supervision of children’s online activities. For example, parental control tools which are available online to help provide a child-friendly online environment. These tools can also prevent children from disclosing their name, address, and other Personal Information online without parental permission. Your child’s privacy is important to us and we are committed to safeguarding children’s Personal Information collected online. Users of our Service may participate in many activities without providing any Personal Information. However, if you’d like to participate in certain interactive features on our Service, we’ll ask you to provide us with certain information, such as your email address and age. We will not send any marketing communications to anyone who has disclosed that they are aged under 16.</p>
            <h3>Changes to this Privacy Policy</h3>
            <p>From time to time, we may revise this Privacy Policy to reflect new company initiatives, services, changes in the law or technology. Any changes we make to this Privacy Policy will be posted on this service so that you are always informed of the latest version. We’ll also include the date of the most recent revisions at the top of this page.</p>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}

export default TermAndcondition;
