import React from 'react'
import { useGlobalContext } from "../../context/Context";
import chili from "../chili.png"
import nonveg from "../leftcontainerCmp/nonveg.png";
import veg from "../leftcontainerCmp/veg.png";
import TESTIMG from "../TESTIMG.jpg";
function GroupId1({add,minus,openModal}) {
    
    const { data, loading } = useGlobalContext();
  return (
    <>
    {data.map((value) => {
              // const{Description,ImageName,vMenuDescription,price,productQty}=value;
              const spicyCount = value.bSpicy;
              if(value.productGroupID===1)
              return (
                <>
                  <div
                    className="card"
                    style={{
                      width: "27%",
                      marginTop: "2px",
                      padding: "7px",
                      boxShadow:
                        "rgb(0 0 0 / 12%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px",
                      marginRight: "1rem",
                      marginBottom: "1rem",
                      display: "flex",
                      flexWrap: "wrap",
                      background: "white",
                      height:"25.5rem"
                    }}
                  >
                    <img
                      style={{ width: "100%", height: "12rem" }}
                      src={TESTIMG}
                      className="card-img-top"
                      alt=""
                    />
                    <div className="vegchillicont">
                      {value.bVegetarain ? (
                        <img
                          style={{ width: "18px", height: "18px" }}
                          src={veg}
                          alt="veg"
                        />
                      ) : (
                        <img
                          style={{ width: "18px", height: "18px" }}
                          src={nonveg}
                          alt="non-veg"
                        />
                      )}
                      {spicyCount <= 0 ? (
                        <div>&#8203;</div>
                      ) : (
                        <div>
                          {[...Array(spicyCount)].map((e) => (
                            <img
                              style={{ width: "15px", height: "15px" }}
                              src={chili}
                              alt=""
                            />
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="card-body">
                      <h5
                        style={{ fontSize: "1rem", marginTop: "-12px" }}
                        className="card-title"
                      >
                        {value.description
                          ? value.description.slice(0, 16) + `...`
                          : ""}
                      </h5>
                      <p
                        style={{ fontSize: "9px", color: "grey" }}
                        className="card-text"
                      >
                        {value.menuDescripton
                          ? value.menuDescripton.slice(0, 70) + `..`
                          : "Description not available"}
                        <span
                          style={{
                            color: "#5182cb",
                            fontSize: "10px",
                            padding: "5px",
                            cursor: "pointer",
                            fontWeight: "800",
                            textDecoration: "underline",
                          }}
                          onClick={() => openModal(value)}
                        >
                          Read More
                        </span>
                      </p>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        className="modalcont"
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "12px",
                        }}
                        className="lower"
                      >
                        <span
                          style={{
                            color: "#134e13",
                            font: "bold",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          £{value.price}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            maxWidth: "3rem",

                            justifyContent: "space-between",
                          }}
                          className="btnplusminus"
                        >         
                          <button
                          className="buttoncard"
                            style={{
                              border: "1px solid #896f6f",
                              height: "fit-content",
                              borderRadius: "3px",
                              fontSize: "12px",
                              background:"rgb(171 113 113)",
                              color:"white",
                              fontWeight:800,

                            }}
                            onClick={() => minus(value.productID)}
                          >
                            -
                          </button>
                          <p
                            style={{
                              
                              fontSize: "16px",
                              marginRight: "9px",
                              marginLeft: "9px",
                              
                            }}
                          >
                            {" "}
                            {value.productQty}{" "}
                          </p>
                          <button
                           className="buttoncard"
                            style={{
                              border: "1px solid #896f6f",
                              height: "fit-content",
                              borderRadius: "3px",
                              fontSize: "12px",
                              background:"rgb(171 113 113)",
                              color:"white",
                              fontWeight:800,
                              
                            }}
                            onClick={() => add(value.productID)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
    </>
  )
}

export default GroupId1;