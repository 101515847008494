import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkoutform from "./Checkoutform";
import "./Stripe.css";
import visaImg from "./img/visaImg.png";
import { useGlobalContext } from "../../context/Context";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51H199hFKr3czhxcVeE7uJmFZUZq2JqyiN0WEVGcdUqFLcm0gNAmtzdgw9wA6dMP85KqGndfz84XLYt4Sm563F4RY00kshZDkY4"
);

const successMessage = () => {
  setTimeout(() => {
    window.open("/", "_self");
  }, 2000);
  return (
    <div className="success-msg">
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-check2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        />
      </svg>
      <div className="title">Payment Successful</div>
    </div>
  );
};

function Stripe() {
  const { total, takeAwayOrderId } = useGlobalContext();
  window.sessionStorage.setItem("takeid", takeAwayOrderId);
  // console.log( window.sessionStorage.getItem("takeid"))
  // console.log("takeawayid", takeAwayOrderId);
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  return (
    <div className="container">
      <div className="visa py-5 text-center">
        <h4>We also accept </h4>
        <img src={visaImg} alt="" />
      </div>
      <div className="wholestrip">
        <div className="stripecont">
          {paymentCompleted ? (
            successMessage()
          ) : (
            <React.Fragment>
              <div className="col-md-7 order-md-3">
                <Elements stripe={stripePromise}>
                  <Checkoutform
                    // amount={total }
                    amount={total}
                    takeAwayOrderId={takeAwayOrderId}
                    setPaymentCompleted={setPaymentCompleted}
                  />
                </Elements>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default Stripe;
