import React from 'react'
import "../Style.css";
import { useNavigate } from "react-router-dom";
import Cartempty from '../leftcontainerCmp/Cartempty.jpg';
function NoOrder() {
    const navigate = useNavigate();
  return (
    <div className="show">
    <div className="orderhistoryData">
      <div className="cartEmptyContainer">
        <img
          style={{ width: "20rem" }}
          src={Cartempty}
          alt="cart is empty "
        />
        <h3
          style={{
            fontWeight: "900",
            fontSize: "1.4rem",
            textTransform: "uppercase",
            color: "rgb(113, 128, 145)",
            width: "100%",
          }}
        >
          No order available
        </h3>
        <p
          style={{
            fontWeight: "600", 
            color: "rgb(113, 128, 145)",
            width: "100%",
          }}
        >
          Please add some items from the menu.
        </p>
        <button
          onClick={() => navigate("/")}
          className="orderhistoryExplorebtn"
        >
          {" "}
          EXPLORE MENU
        </button>
      </div>
    </div>
  </div>
  )
}

export default NoOrder