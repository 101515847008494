import React, { useState } from "react";
import { useGlobalContext } from "../context/Context";
import TESTIMG from "./TESTIMG.jpg";
import chili from "./chili.png";
import Modals from "./leftcontainerCmp/Modal";
import veg from "./leftcontainerCmp/veg.png";
import nonveg from "./leftcontainerCmp/nonveg.png";
import "./leftcontainerCmp/leftcss.css";
import Loadingspinner from "./Loadingspinner";
import GroupId1 from "./Foodlist/GroupId1";
import GroupId2 from "./Foodlist/GroupId2";
import GroupId3 from "./Foodlist/GroupId3";
import "./Foodlist/GRoup.css";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import SecondNav from "./SecondNav";
function Card({ add, minus, displaylist, setDisplaylist }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modaldata, setmodaldata] = useState(null);
  const { data, loading, heading, setheading } = useGlobalContext();

  const openModal = (data) => {
    // data coming from onclick as value.
    setmodaldata(data);
    setModalShow(true);
  };
  function fnid() {}
  // console.log(heading);
  return (
    <>
      <SecondNav />

      {loading ? (
        <Loadingspinner />
      ) : (
        <>
          {modaldata && (
            <Modals
              modalShow={modalShow}
              setModalShow={setModalShow}
              data={modaldata}
            />
          )}
        <p id="Appetisers">&nbsp;</p>
       
          <div className="grpHead1"  onClick={fnid()}>
            <h2 >Appetisers</h2>
            <div className="cardAlign">
              <GroupId1 add={add} minus={minus} openModal={openModal} />
            </div>
          </div>
         
          <h5 id="Noodle Dishes">&nbsp;</h5>
          <div className="grpHead" >
            <h2>Noodle Dishes</h2>

            <div className="cardAlign">
              <GroupId2 add={add} minus={minus} openModal={openModal} />
            </div>
          </div>
          <h5 id="Thai Salad">&nbsp; </h5>
          <div className="grpHead" >
            <h2>Thai Salad</h2>
            <div className="cardAlign">
              <GroupId3 add={add} minus={minus} openModal={openModal} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Card;
