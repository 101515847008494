import React from 'react'

function Loadingspinner() {
  return (
    <div style={{marginTop:"12rem"}}class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  )
}

export default Loadingspinner