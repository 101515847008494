import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./faqstyle.css";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>
            Can gift cards be used along with credit cards / other wallet for
            purchasing{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When placing an order online, the only other option is Cash on
            Delivery if full payment is not made through gift cards. In stores,
            gift cards can be used along with any other payment mode.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            Is there any expiry time for the OTP genrated{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No. OTP can be used only once, but there is no set expiry time for
            OTPs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            Expiry date on the card is mentioned as dd-mm-yy. till when can the
            card be used for redemption
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The gift card can be used until midnight 11:59PM of dd-mm-yyyy.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            Can i purchase more than 1 gift card at a time
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, up to 10 Gift Cards during a transaction on
            www.sordizTakeaway.com
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>What is validity of Electronic Gift Voucher ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Electronic Voucher is valid for 6 months from date of issuance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>What is validity of Paper Gift Voucher ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Paper Voucher validity is fixed and is mentioned on voucher back
            side.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            In what denominations is Sordiz takeaway paper voucher sold ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In 3 fixed denominations INR 100, INR 200 and INR 500.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>What are your delivery charges</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dear Sir/ Mam, This is a nominal fee charged as an industry practice
            on all our delivery orders till 11PM. Per delivery charge is minimum
            INR 35 (inclusive of taxes) for orders INR 300 and varies as per
            order value for (less than)INR 300. This charge is to ensure highest
            standard of hygiene protocols being followed to deliver hot and
            fresh pizzas.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>What are your night delivery charges ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dear Sir/ Mam, This is a nominal fee charged as an industry practice
            on all our night delivery orders from 11PM to 3AM. Per delivery
            charge is minimum INR 40 (inclusive of taxes)for orders (greater
            than) INR 300 and varies as per order value for (less than)INR 300.
            This charge is to ensure highest standard of hygiene protocols being
            followed to deliver hot and fresh pizzas during late hours
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>What are your night delivery charges slab ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dear Sir/ Mam, The delivery charge varies as per the order value.
            Per delivery charge is INR 35 for all orders INR 300, INR 45 for
            order value between INR 157 upto INR 300, INR 55 for order value
            between INR 27 upto INR 156 and INR 65 for order value below INR 27.
            All delivery charges are inclusive of applicable taxes.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            What are the precautions taken beacause of Bird Flu spread ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dear Mam / Sir, we follow stringent SOPs to ensure that your Food is
            prepared with utmost hygiene and safety. We source our meat only
            from FSSC22K certified suppliers, where we ensure strict hygiene &
            bird health safety measure and rearing of bird in bio secured farms.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            What are the practices adopted by JFL to ensure No Bird Flu (Avian
            Influenza)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We follow stringent SOPs to ensure that your food is prepared with
            utmost hygiene and safety.
            <li>
              Two stage verification (by Processor and JFL representative) of
              Stringent Biosecurity measures and access control systems in place
              for all Farms and Plants.
            </li>
            <li>
              100% meat supplies are verified and monitored by competent
              veterinarians at Farm/Processing unit and ensures cleaning/
              sanitation protocol and bird health management program in place.
            </li>
            <li>
              Poultry Health Management system which includes the vaccination,
              nutrition and treatment protocols & no usage of antibiotic in the
              entire bird life cycle.
            </li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel13"}
        onChange={handleChange("panel13")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Where JFL Poultry meat does come from ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            JFL poultry supplies are only from approved FSMS certified poultry
            processing centers which has its own Integrated Farms with stringent
            Biosecurity and environmental control & bird health management
            program.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel14"}
        onChange={handleChange("panel14")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            Does JFL supplies are affected by recent Bird Flu (Avain influenza )
            outbreak ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No there is no impact on our supplies, at JFL we have defined Avian
            Influenza outbreak monitoring and mitigation program for its Poultry
            products.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel15"}
        onChange={handleChange("panel15")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            what is the option of cancellation of orders / How can i cancel my
            order ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dear User, You can cancel your order by clicking on the "Cancel
            Order" button on the Order Confirmation page. Currently the option
            is only applicable for Cash on Delivery Orders.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel16"}
        onChange={handleChange("panel16")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            I have placed order from call center/Pwa/ Aggregtor and i am unable
            to Cancel my order
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dear User, Self Order cancellation can only be done for orders
            placed via Sordiz TakeAway Application and not applicable for orders
            placed from other sources.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel17"}
        onChange={handleChange("panel17")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>what are the cancellation charges ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Currently cancellation option is available only for Cash on Delivery
            Orders. There are no cancellation charges for the first 60 seconds
            or if the order is not delivered even after 60 minutes on regular
            days and 90minutes during festivals (festivals/big occasions like
            New Year eve, Ganesh Festival, Christmas, Durga Pooja, etc). If the
            order is cancelled after 60 sec, Cash On Delivery option will be
            disabled for next order.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel18"}
        onChange={handleChange("panel18")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Till when can i cancel my order ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can cancel your order until delivery executive reaches your location (First barrier point).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel19"}
        onChange={handleChange("panel19")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>what is the penalty if i cancel my order beyond free cancellation period ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Dear User, Cash payment will be blocked for your next order. Cash payment will be enabled after successful delivery of next prepaid order.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel20"}
        onChange={handleChange("panel20")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Why i am not able to cancel my cash on delivery order</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Dear User, Once the Delivery Executive reaches your location (First Barrier Point), you will not be able to Cancel your Order.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
