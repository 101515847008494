import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Style.css";
import TESTIMG from "../TESTIMG.jpg";
function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(props.value.value);

  return (
    <>
      <button onClick={handleShow} className="btnhistory">
        VIEW DETAILS
      </button>
      <Offcanvas
        style={{ marginTop: "2.5rem", transition: "all 1s ease 0s" }}
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Order Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="orderNumberContainer">
            <p className="ordernumberPara">
              Order No. {props.value.value.orderNumber}
            </p>
            <p className="ordernumberDatepara">
              {" "}
              Order Placed on {props.value.value.dateCreated.slice(0, 10)} for
              Amount of £{props.value.value.orderTotal}
            </p>
          </div>
          <div className="orderNumberContainer">
            <p className="orderDeliveryPara">Delivery Address </p>
            <p className="ordernumberDatepara">
              {" "}
              {props.value.value.fullAddress? props.value.value.fullAddress : "41-B, BA Block Janak Puri, BB Block, Janakpuri, New Delhi" }
              {/* 41-B, BA Block Janak Puri, BB Block, Janakpuri, New Delhi */}
            </p>
          </div>
          <div className="OrderHistoryproductList">
            {props.value.value.orderedItems.map((item) => {
              return (
                <>
                  <div className="cardOrderHistory">
                    <div className="cardorderinner">
                      <img
                        style={{
                          marginLeft: "0px",
                          marginTop: "2px",
                          display: "flex",
                          flex: "1 1 0%",
                          height: "4.2rem",
                          borderRadius: "3px",
                        }}
                        src={TESTIMG}
                        alt=""
                      />
                    </div>
                    <div className="Orderhistorycontent">
                      <p style={{ color: "grey", fontWeight: 800 }}>
                        {item.description}
                      </p>
                      <div className="PriceandQtycont">
                        <p>Price: {item.price}</p>
                        <p>Qty: {item.qty}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OrderDetails(value) {
  return (
    <>
      {["start"].map((placement, idx) => (
        <OffCanvasExample
          key={idx}
          placement={placement}
          name={placement}
          value={value}
        />
      ))}
    </>
  );
}

export default OrderDetails;
