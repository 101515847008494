import React from "react";
import Footer from "../Footer/Footer";
import TakeawayNav from "../TakeawayNav";
import CustomizedAccordions from "./Faq";
import "./faqstyle.css";
function FaqHome() {
  return (
    <div className="faqhomewholecontainer">
      <TakeawayNav />
      <div className="contentfaq">
        <h2>Frequently Asked Questions</h2>
        <CustomizedAccordions />
      </div>

      <Footer />
    </div>
  );
}

export default FaqHome;
