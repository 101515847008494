import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useGlobalContext } from "../../context/Context";
import "../Style.css";

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [PostAddress, setPostAddress] = useState([]);
  const [postcodenumber, setpostcodenumber] = useState();
  const [pincodeStatus, setpincodeStatus] = useState(false);
  const [showAddresscontainer, setshowAddresscontainer] = useState(false);
  const { setDeliveryAddress, DeliveryAddress } = useGlobalContext();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const url="http://tcbapi.thechinesebuffet.com/GetTakeAwaySlots?resId=1&type=Collection"
  // const datafetch=async()=>{
  //         const response = await fetch(url);
  //         const data = response.json();
  //         console.log("hkgfgfgfgasgfi",data );
  // }
  // datafetch();
  const getAddressByPostcodeURL = `https://api.sordiz.com/GetAddressesByPostCode?postCode=${postcodenumber}`;
  // bl12eh
  const getPostcodeAddress = async () => {
    const response = await fetch(getAddressByPostcodeURL);
    const data = await response.json();
    // console.log(data);
    if (data.addresses.length === 0) {                                                        
      // console.log("emptydata");                                                        
      setpincodeStatus(false);                                                        
    } else {                                                        
      // console.log("filleddata");                                                        
      setpincodeStatus(true);                                                        
    }                                                        
    if (postcodenumber === "") {                                                        
      setshowAddresscontainer(false);                                                        
      // console.log("empty");                                                        
      alert("Postcode can't be empty");                                                        
    } else {                                                        
      setshowAddresscontainer(true);                                                        
      // console.log("filled");      
                                                      
    }                                                        
    setPostAddress(data.addresses);                                                        
  };                                                        
  const DeliveryAddressStore = (e) => {                                                        
    // console.log(e.target.textContent);     
    localStorage.setItem("DeliveryAddress", e.target.textContent);      
    let DeliveryAddress = localStorage.getItem("DeliveryAddress");                                             
    setDeliveryAddress(e.target.textContent);      
    setShow(false);                                                   
  };      
  let DeliveryAddresslocal = localStorage.getItem("DeliveryAddress");  
  // console.log(DeliveryAddresslocal);                                                  
  // console.log(DeliveryAddress);
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          onClick={handleShow}
          readOnly
        />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Delivery
        </label>
      </div>

      <Offcanvas
        style={{ marginTop: "2.5rem", transition: "all 1s ease 0s" }}
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> Delivery</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="delivercont">
            <div className="delivercontInner">
              <p className="HeadOfDeliver">
                We will deliver right to your door
              </p>
              <div className="inputDelviver">
                <input
                  className="areaSearchBar"
                  type="text"
                  placeholder="Enter Postcode"
                  onChange={(e) => setpostcodenumber(e.target.value)}
                  value={postcodenumber}
                />
                <button onClick={getPostcodeAddress} className="locatemeBtn">
                  LOCATE ME{" "}
                </button>
              </div>
              {showAddresscontainer ? (
                <>
                  {" "}
                  <div className="addressDisplaycontainer">
                    {pincodeStatus ? (
                      <>
                        {PostAddress.map((item) => {
                          return (
                            <p
                              onClick={(e) => DeliveryAddressStore(e)}
                              className="addressshown"
                            >
                              {item.address}
                            </p>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {" "}
                        <p className="invalidpostcode">Invalid Postcode</p>
                      </>
                    )}
                  </div>{" "}
                </>
              ) : (
                <></>
              )}

              <p className="footerDeliver">
                Please select Postcode, so that we can find a restaurant that
                delivers to you!
              </p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Deliveryoffcanvas() {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default Deliveryoffcanvas;
