import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/Context";
import "../Style.css";
import TESTIMG from "../TESTIMG.jpg";
import nonveg from "./nonveg.png";
import veg from "./veg.png";
import CartEmpty from "./CartEmpty";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Cart.css";
import Example from "../LoginOffcanvas";

function Cart({ add, minus }) {
  const {
    cartdata,
    settotal,
    total,
    show,
    setShow,
    businessdata,
    setloading,
    settakeAwayOrderId,
  } = useGlobalContext();
  const [popshow, setPopshow] = useState(false);
  const [color, setColor] = useState("");
  const [error, setError] = useState("");
  const [catcherror, setCatchError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {}, [cartdata]);

  let postarr = [];
  for (const element of cartdata) {
    postarr.push(element);
  }
  let username = localStorage.getItem("customerName");
  let mobile = localStorage.getItem("customerMobileNumber");
  const transformed = postarr.map(
    ({ productQty, description, price, productID }) => ({
      qty: productQty,
      description: description,
      price: price,
      productID: productID,
    })
  );
for(let i = 0 ; i< transformed.length ; i++){
  if(transformed[i].qty === 0){
    transformed.splice(i,1)
  }
}
console.log("tras",transformed);
  //! -----------------------------sub Total code--------------------------------------------------- //
  let subtotal = 0; 
  cartdata.forEach((item) => {
    subtotal += Number(item.price * item.productQty);
  });  
  settotal(subtotal.toFixed(2));
  // console.log("uisugu", cartdata);
  //! -----------------------------sub Total code--------------------------------------------------- //
  let testtakeaway = window.sessionStorage.getItem("pickup")
  let testdelivery = window.sessionStorage.getItem("delievry")
  let takeaway = (testtakeaway === "true")
  let delievry = (testdelivery === "true")
    
  let DeliveryAddresslocal = localStorage.getItem("DeliveryAddress");
  // console.log(window.sessionStorage.getItem("ordertype"))
  const cardfnc = async () => {
    const url = "https://api.sordiz.com/SubmitTakeAwayOrder";
    let data = {
      customerId: Number(id),
      businessId: businessdata,
      orderedItems: transformed,
      orderTotal: Number(total),
      paymentType: true,
      paid:false,
      customerName: username,
      customerPhone: mobile,
    };
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        // console.log(response.data[0].takeAwayOrderId); //!takeAwayOrderid
        settakeAwayOrderId(response.data[0].takeAwayOrderId);
        if (response.status === 1) {
          setloading(false);
          setPopshow(true);
          setColor("#8e8e7c");
          setError(response.message);
          console.log(response.message);
        } else {
          setPopshow(true);
          setColor("#C70100");
          setError(response.message);
          console.log(response.message);
        }
      })
      .catch((err) => {
        setCatchError(true);
        setColor("#C70100");
      });
    navigate("/payment");
  };
  // console.log("order",transformed);
  async function onSubmit() {
    if(transformed.length === 0){
      console.log("sselect");
      alert("Select Some Product ")
    }else{
    setloading(true);
    const url = "https://api.sordiz.com/SubmitTakeAwayOrder";
    let data = {
      customerId: Number(id),
      businessId: businessdata,
      orderedItems: transformed,
      orderTotal: Number(total),
      paymentType: false, 
      customerName: username,
      customerPhone: mobile,
      fullAddress: DeliveryAddresslocal,
      takeAway:takeaway,
      delivery:delievry,
    };
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        if (response.status === 1) {
          setloading(false);
          setPopshow(true);
          setColor("#8e8e7c");
          setError(response.message);
          console.log(response.message);
        } else {
          setPopshow(true);
          setColor("#C70100");
          setError(response.message);
          console.log(response.message);
        }
      })
      .catch((err) => {
        setCatchError(true);
        setColor("#C70100");
      });
    } 
  }

  let islogin = JSON.parse(localStorage.getItem("islogin"));
  let iscardshow = JSON.parse(localStorage.getItem("cardshow"));
  const checkoutfnc = () => {
    localStorage.setItem("islogin", true);
    islogin = JSON.parse(localStorage.getItem("islogin"));

    setShow(true);
  };
  // islogin = JSON.parse(localStorage.getItem("islogin"));
  // console.log("USER-LOGINED-STATUS", userlogined);
  let id = localStorage.getItem("customerID");
  // console.log(islogin, "Apidata1");
  // console.log(iscardshow, "Apidata2");
  // let testprice = []
  // console.log("testprice",testprice);
  return (
    <>
      {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className="cardbox" style={{ backgroundColor: color }}>
                <div className="erroriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <p className="text-center text-white">{error}</p>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn"
                    onClick={() => {
                      setPopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* <h2 style={{ fontWeight: 600, display: "flex" }}>Cart</h2> */}

          <div
            style={{
              marginTop: "4.1rem",
              position: "fixed",
              minHeight: "28rem",
              maxHeight: "65vh",
              width: "19rem",
              background: "rgb(255, 255, 255)",
              // overflowY: "scroll",
              transition: "all 1s ease 0s",
              marginLeft: "0px",
              boxShadow:
                "rgb(0 0 0 / 12%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px",
            }}
            className="cartcontainer"
          >
            <>
              <div
                style={{
                  borderTop: "2px solid rgb(88, 30, 30)",
                  margin: "0px",
                }}
                className="outer"
              >
                
               
                
                {cartdata && cartdata.length >0 ? (
                  <>
                    {" "}
                    <div className="innercartcontainer" >
                    {cartdata.map((value) => {
                      return (
                        <> 
                        <div
                          style={{
                            border: "1px solid rgb(177 166 166)",
                            display: "flex",

                            padding: "3px",
                            flexDirection: "row",
                          }}
                          className="itemcontainer"
                        >
                          <div className="CartImageContainer">
                            <img
                              style={{
                                marginLeft: "0px",
                                marginTop: "2px",
                                display: "flex",
                                flex: "1 1 0%",
                                height: "4.2rem",
                                borderRadius: "3px",
                              }}
                              src={TESTIMG}
                              alt=""
                            />
                            <span
                              style={{
                                position: "relative",
                                top: "51px",
                                right: "64px",
                              }}
                            >
                              {" "}
                              {value.bVegetarain ? (
                                <img
                                  style={{ width: "18px", height: "18px" }}
                                  src={veg}
                                  alt="veg"
                                />
                              ) : (
                                <img
                                  style={{ width: "18px", height: "18px" }}
                                  src={nonveg}
                                  alt="non-veg"
                                />
                              )}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: "3 1 0%",
                            }}
                            className="cartvaluescont"
                          >
                            <p style={{ fontWeight: "700" }}>
                              {" "}
                              {value.description}
                            </p>
                            <p style={{ fontSize: "11px", color: "grey" }}>
                              {value.menuDescripton ? (
                                value.menuDescripton.slice(0, 95) + `.....`
                              ) : (
                                <span>Description not available</span>
                              )}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginTop: "1rem",
                              }}
                              className="qtyandprice"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  width: "90px",
                                }}
                                className="btnqty"
                              >
                                {/* //!----------- ////////////button minus plus  */}
                                <button
                                  style={{
                                    border: "1px solid #896f6f",
                                    height: "fit-content",
                                    borderRadius: "3px",
                                    fontSize: "12px",
                                    background: "rgb(171 113 113)",
                                    color: "white",
                                    fontWeight: 800,
                                  }}
                                  onClick={() => minus(value.productID)}
                                >
                                  -
                                </button>
                                {value.productQty}
                                <button
                                  style={{
                                    border: "1px solid #896f6f",
                                    height: "fit-content",
                                    borderRadius: "3px",
                                    fontSize: "12px",
                                    background: "rgb(171 113 113)",
                                    color: "white",
                                    fontWeight: 800,
                                  }}
                                  onClick={() => add(value.productID)}
                                >
                                  +
                                </button>
                              </div>
                              <p
                                style={{
                                  color: "#134e13",
                                  fontWeight: "700",
                                  fontSize: "13px",
                                }}
                              >
                                Price : £
                                {Math.round(
                                  (value.price * value.productQty +
                                    Number.EPSILON) *
                                    100
                                ) / 100}
                              </p>
                            </div>
                          </div>
                        </div>
                        </>
                      );
                      
                    })}
                    </div>
              
                    {/* //? Subtotal? --------------------------------------------------------------------- */}
                    <div className="promocode">
                      <p className="applypara">Apply Promocode if any </p>
                      <div className="promosearchAndBtn">
                        <input
                          className="inputpromocode"
                          type="text"
                          placeholder=""  
                         
                        />
                        <button className="promocheckbtn" >Check</button>
                      </div>
                    </div> 
                    <div
                      style={{
                        position: "sticky",
                        background: "#a15050",
                        bottom: "1px",
                        width: "19rem",
                        height: "4.6rem",
                        paddingTop: "6px ",
                        color: "white",
                        overflow: "hidden",
                      }}
                      className="footercart"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          fontSize: "14px",
                        }}
                      >
                        <p>Subtotal</p>
                        <p>£:{total}</p>
                      </div>
                    
                   
                      <div className="checkoutbtn">
                 
                        {islogin ? (
                          <>
                            <Example show={show} setShow={setShow} />
                          </>
                        ) : (
                          <>
                            {iscardshow ? (
                              <>
                    
                                {" "}
                                <button
                                  className="checkoutbtn1"
                                  onClick={onSubmit}
                                >
                                  cash
                                </button>
                                <button
                                  className="checkoutbtn1"
                                  onClick={cardfnc}
                                >
                                  card
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="checkoutbtn1"
                                  onClick={checkoutfnc}
                                >
                                  Checkout
                                </button>{" "}
                              </>
                            )}
                          </>
                        )}
                      </div>
                   
                    </div>
                  </>
                ) : (
                  <CartEmpty />
                )}
                
              </div>
            </>
            
          </div>

          {popshow ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv">
                  <div className="cardbox" style={{ backgroundColor: color }}>
                    {color === "#C70100" ? (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-badge-chech"></i>
                        </div>
                      </>
                    )}

                    <br />
                    <p className="text-center text-white">{error}</p>
                    <br />
                    <div className="mainerrorclosebtn">
                      {color === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn"
                            id="close-pop"
                            onClick={() => {
                              setPopshow(false);
                            }}
                          >
                            <p onClick={() => window.location.reload()}>OK</p>
                          </div>
                        </>
                      ) : (
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <div
                            className="errorclosebtn"
                            onClick={() => {
                              setPopshow(false);
                            }}
                          >
                            <p onClick={() => window.location.reload()}>OK</p>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

export default Cart;
